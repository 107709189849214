import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'

import { Layout, Wrapper, Header, Subline, SEO, PrevNext, Text } from '../components'

const Content = styled.article`
  grid-column: 2;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  border-radius: 1rem;
  padding: 2rem 4.5rem;
  background-color: ${props => props.theme.colors.bg};
  z-index: 9000;
  margin-top: -3rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  p {
    font-size: 1.1rem;
    letter-spacing: -0.003em;
    line-height: 1.58;
    --baseline-multiplier: 0.179;
    --x-height-multiplier: 0.35;
    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      font-size: 1rem;
    }
  }

  .prism-code {
    padding: 0.75rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    font-size: 16px;
  }
`

const Title = styled.h1`
  margin-bottom: 1rem;
`

const BlogContent = styled.div`
  margin-top: 4rem;
`

export const BlogTemplate = ({
    language,
    title,
    date,
    body,
    timeToRead,
    categories,
    prev,
    next
}) => {
  return (
    <Content>
      <Title>{title}</Title>
      <Subline>
        {date} &mdash;
        {timeToRead} <Text>Min Read</Text> &mdash;
        <Text>In</Text>{' '}
        {categories.map((cat, i) => (
          <React.Fragment key={cat}>
            {!!i && ', '}
            <Link to={`/${language}/categories/${kebabCase(cat)}`}>{cat}</Link>
          </React.Fragment>
        ))}
      </Subline>
      <BlogContent>
        <MDXRenderer>{body}</MDXRenderer>
      </BlogContent>
      <PrevNext prev={prev} next={next} />
    </Content>
  )
}

const Blog = ({ pageContext: { language, slug, prev, next }, data: { mdx: blogNode } }) => {
  const blog = blogNode.frontmatter

  return (
    <Layout customSEO>
      <Wrapper>
        <SEO postPath={slug} node={blogNode} article />
        <Header languabge={language}/>
        <BlogTemplate
            language={language}
            title={blog.title}
            date={blog.date}
            body={blogNode.code.body}
            timeToRead={blogNode.timeToRead}
            categories={blog.categories}
            prev={prev}
            next={next}/>
      </Wrapper>
    </Layout>
  )
}

Blog.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    next: PropTypes.object,
    prev: PropTypes.object,
  }),
  data: PropTypes.shape({
    mdx: PropTypes.object.isRequired,
  }).isRequired,
}

Blog.defaultProps = {
  pageContext: PropTypes.shape({
    next: null,
    prev: null,
  }),
}

export default Blog

export const blogQuery = graphql`
  query blogBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      code {
        body
      }
      excerpt
      frontmatter {
        title
        date(formatString: "MM/DD/YYYY")
        categories
      }
      timeToRead
      parent {
        ... on File {
          mtime
          birthtime
        }
      }
    }
  }
`
